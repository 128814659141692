import {
  ContainerMFE,
  FeatureFlagKeyValues,
  SectionHeader,
  useGetFeatureFlags
} from '@hpx-core-experiences/react-my-account-commons/dist';
import {
  LookingForSomethingElse,
  publishEvent
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import React, { useMemo } from 'react';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { screenName, screenPath } from 'src/utils/analytics';
import { URLS } from 'src/utils/urls';
import { HpStoreWalletButtonClicked } from './analytics';
import PaymentsLogic from './PaymentsLogic';
import { Content, FindMoreText, Header } from './styles';

export interface FeatureFlagProps {
  isLoading: boolean;
  originalFeedbackExperience: boolean;
}

const Payments = (): JSX.Element => {
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const featureFlagClient = useMemo(
    () => northboundAPIs?.v1?.featureFlags?.getClient,
    [northboundAPIs?.v1?.featureFlags?.getClient]
  );

  const {
    originalFeedbackExperience: showOriginalFeedbackExperience,
    isLoading
  } = useGetFeatureFlags({
    getClient: featureFlagClient,
    keys: [FeatureFlagKeyValues.originalFeedbackExperience]
  });

  const htmlString = translate(
    'payments.findMoreCardsAndPayment',
    'Find more cards and payment methods in your {{tagReplaceStart}}HP Store wallet{{tagReplaceEnd}}.'
  );
  const [textBeforeLink, linkText, textAfterLink] = htmlString.split(
    /{{\/?tagReplaceStart}}|{{\/?tagReplaceEnd}}/
  );

  return (
    <ContainerMFE>
      <Header>
        <SectionHeader
          title={translate('orders.paymentMethods', 'Payment methods')}
          subtitle={translate(
            'payments.viewAndManagePaymentMethods',
            'View and manage your payment methods'
          )}
        />
      </Header>
      <Content>
        <div>
          <PaymentsLogic />
          <FindMoreText>
            <p>
              {textBeforeLink}
              <a
                data-testid="hp-store-wallets-button"
                href={URLS.HpStoreWallet}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => publishEvent(HpStoreWalletButtonClicked)}
              >
                {linkText}
              </a>
              {textAfterLink}
            </p>
          </FindMoreText>
        </div>
        {showOriginalFeedbackExperience && !isLoading && (
          <LookingForSomethingElse
            analytics={{
              screenName: screenName,
              screenPath: screenPath
            }}
            translate={translate}
          />
        )}
      </Content>
    </ContainerMFE>
  );
};

export default Payments;
