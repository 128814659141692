import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > div {
    width: 100%;
    max-width: 1140px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.layout.size1};
`;

export const FindMoreText = styled.div`
  font-size: ${tokens.typography.size2};
  color: ${tokens.color.gray12};
`;
